<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Contact Archive List ">
				<md-table v-model="lists">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.listid }}</md-table-cell>
						<md-table-cell md-label="List Name">{{
							item.listname
						}}</md-table-cell>
						<md-table-cell md-label="Total Contacts">12</md-table-cell>
						<md-table-cell md-label="Actions">
							<md-button
								class="md-just-icon md-success"
								@click.prevent="activateContactList(item)"
								><md-icon>edit</md-icon></md-button
							>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
	components: {
		Table,
	},
	computed: {
		...mapGetters({
			lists: "contact/archivedList",
		}),
	},
	methods: {
		...mapActions({
			activateList: "contact/activateList",
		}),
		activateContactList(list) {
			Swal.fire({
				title: "Activate!",
				text: "Are you sure you want to activate " + list.listname + "?",
				type: "warning",
				showCancelButton: true,
				confirmButtonClass: "md-button md-success",
				cancelButtonClass: "md-button md-default",
				confirmButtonText: "Yes, Proceed!",
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.activateList({ listid: list.listid });
				}
			});
		},
	},
	mounted() {
		this.$store.dispatch("contact/getArchivedList");
	},
};
</script>
