import axios from "axios";
import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });
let accountno = null;
let userid = null;
let user = null;

if (ls.get("vuex")) {
  user = JSON.parse(ls.get("vuex"));
}

if (user && user.auth && user.auth.currentUser) {
  let current_user = JSON.parse(ls.get("vuex")).auth.currentUser;

  if (Object.keys(current_user).length) {
    accountno = current_user.accountno;
    userid = current_user.userid;
  }
}

const Request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  params: {
    accountno: accountno,
    userid: userid,
  },
  // timeout: 5000,
});

Request.interceptors.request.use(
  (config) => {
    commitData(true, null);
    NProgress.start();

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Request.interceptors.response.use(
  (resp) => {
    NProgress.done();

    let response = resp.data;
    if (
      response.statuscode == 200 ||
      response.statuscode == 201 ||
      !response.statuscode
    ) {
      commitData(false, null);
      store.commit("shared/setSuccessResponse", response.description);
      return response;
    }
    commitData(false, response.description);
  },
  (error) => {
    NProgress.done();
    commitData(
      false,
      "Something went wrong, we're unable to process your request, please try again!"
    );

    return error;
  }
);

const commitData = function (type, msg) {
  store.commit("shared/setLoading", type);
  store.commit("shared/setErrorResponse", msg);
};

export default Request;
