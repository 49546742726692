<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Scheduled Robocall List" class="md-size-66">
				<md-table v-model="robocalls">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.scheduleid }}</md-table-cell>
						<md-table-cell md-label="Created Date ">{{
							item.datacreated
						}}</md-table-cell>
						<md-table-cell md-label="Account Code ">{{
							item.cardnumber
						}}</md-table-cell>
						<md-table-cell md-label="Scheduled Date">{{
							item.scheduledate
						}}</md-table-cell>
						<md-table-cell md-label="Target List">{{
							"Contact List"
						}}</md-table-cell>
						<md-table-cell md-label="Voice File">{{
							"Voice Recording"
						}}</md-table-cell>
						<md-table-cell md-label="Status">{{ item.status }}</md-table-cell>
						<md-table-cell md-label="Actions">
							<drop-down class="position-static">
								<md-button
									slot="title"
									class="md-button md-success md-round dropdown-toggle"
									data-toggle="dropdown"
									style="width: 90px; min-width: unset; padding-top: 2px"
								>
									Choose
								</md-button>
								<ul
									class="dropdown-menu"
									:class="{ 'dropdown-menu-right': !responsive }"
								>
									<li>
										<a href="#">Archive</a>
									</li>
									<li><a href="#">Repeat RoboCall</a></li>
									<li>
										<a href="#">Cancel RoboCall</a>
									</li>
									<li>
										<a href="#">Stop RoboCall</a>
									</li>
								</ul>
							</drop-down>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>

			<div class="md-layout-item md-medium-size-100 md-size-33">
				<form @submit.prevent="save()" action="" method="post">
					<md-card>
						<md-card-header class="md-card-header-icon md-card-header-green">
							<!-- <div class="card-icon">
								<md-icon>mail_outline</md-icon>
							</div> -->
							<h4 class="title">Schedule New Robocall</h4>
							<p class="text-danger">
								SERVER TIME: Thursday 18th of August 2022 11:32:08 AM
							</p>
						</md-card-header>
						<md-card-content>
							<hr />
							<div class="md-layout">
								<div class="md-layout-item md-size-33">
									<md-field>
										<label for="year">Year</label>
										<md-select v-model="year" name="year" id="year">
											<md-option value="">--select year--</md-option>
											<md-option
												v-for="range in range(2022, 2050)"
												:key="range"
												:value="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-33">
									<md-field>
										<label for="month">Month</label>
										<md-select v-model="month" name="month" id="month">
											<md-option value="">--select month--</md-option>
											<md-option value="01">January</md-option>
											<md-option value="02">February</md-option>
											<md-option value="03">March</md-option>
											<md-option value="04">April</md-option>
											<md-option value="05">May</md-option>
											<md-option value="06">June</md-option>
											<md-option value="07">July</md-option>
											<md-option value="08">August</md-option>
											<md-option value="09">September</md-option>
											<md-option value="10">October</md-option>
											<md-option value="11">November</md-option>
											<md-option value="12">December</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-33">
									<md-field>
										<label for="day">Day</label>
										<md-select v-model="day" name="day" id="day">
											<md-option value="">--select day--</md-option>
											<md-option
												v-for="range in range(1, 31)"
												:key="range"
												:value="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
							</div>
							<div class="md-layout">
								<div class="md-layout-item md-size-33">
									<md-field>
										<label for="hour">Hour</label>
										<md-select v-model="hour" name="hour" id="hour">
											<md-option value="">--select hour--</md-option>
											<md-option
												v-for="range in range(1, 24)"
												:key="range"
												:value="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-33">
									<md-field>
										<label for="minute"> Minute</label>
										<md-select v-model="minute" name="minute" id="minute">
											<md-option value="">--select minute--</md-option>
											<md-option
												v-for="range in range(0, 59)"
												:key="range"
												:value="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-33">
									<md-field>
										<label for="gmt">GMT</label>
										<md-select name="gmt" id="gmt">
											<md-option value="" selected>--select gmt--</md-option>
											<md-option>AM</md-option>
											<md-option>PM</md-option>
										</md-select>
									</md-field>
								</div>
							</div>
							<div class="md-layout-item">
								<md-field>
									<label for="voicetag">Voice File</label>
									<md-select v-model="voicetag" name="voicetag" id="voicetag">
										<md-option value="">--select--</md-option>
										<md-option
											v-for="record in recordings"
											:key="record.voicetag"
											:value="record.voicetag"
											>{{ record.filename }}</md-option
										>
									</md-select>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-field>
									<label for="phonelist">Contact Group</label>
									<md-select
										v-model="phonelist"
										name="phonelist"
										id="phonelist"
										multiple
									>
										<md-option value="">--choose list--</md-option>
										<md-option
											v-for="list in lists"
											:key="list.listid"
											:value="JSON.stringify({ listid: list.listid })"
											>{{ list.listname }}</md-option
										>
									</md-select>
								</md-field>
							</div>
							<!-- <div class="md-layout-item">
								<md-radio v-model="radio1" :value="true">Radio is on</md-radio>
								<md-radio>Send Message Now</md-radio>
								<md-radio>Send Message Later</md-radio>
							</div> -->
						</md-card-content>

						<md-card-actions md-alignment="right">
							<md-button type="submit" :disabled="isLoading" class="md-success"
								>Send</md-button
							>
						</md-card-actions>
					</md-card>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";
import { mapGetters, mapActions } from "vuex";

export default {
	components: {
		Table,
	},
	data() {
		return {
			phonelist: [],
			year: null,
			month: null,
			day: null,
			hour: null,
			minute: null,
			voicetag: null,
			responsive: false,
		};
	},

	computed: {
		...mapGetters({
			user: "auth/currentUser",
			isLoading: "shared/getLoading",
			recordings: "voiceRecording/voiceFiles",
			lists: "contact/contactList",
			robocalls: "robocall/listRobocalls",
		}),
	},

	methods: {
		...mapActions({
			newRobocall: "robocall/newRobocall",
			listRobocalls: "robocall/listRobocalls",
			contactList: "contact/contactList",
			listVoiceFiles: "voiceRecording/listFiles",
		}),

		save() {
			this.newRobocall(this.formData());
		},

		formData() {
			let datetime =
				`${this.year}${this.month}${this.day}${this.hour}${this.minute}` +
				"1010";
			let phonelist = this.phonelist.map((list) => JSON.parse(list));

			let data = {
				token: "'" + this.user.token + "'",
				phonelist: this.stringifyArray(phonelist),
				datetime: "'" + datetime + "'",
				voicetag: this.voicetag,
			};

			return data;
		},

		stringifyArray(params) {
			return "'" + JSON.stringify(params) + "'";
		},

		range(min, max) {
			return [...Array(max - min + 1).keys()].map((i) => {
				let val = i + min;
				return val >= 10 ? val : "0" + val;
			});
		},

		getData() {
			if (this.lists && this.lists.length <= 0) {
				this.contactList();
			}
			if (this.robocalls && this.robocalls.length <= 0) {
				this.listRobocalls();
			}
			if (this.recordings && this.recordings.length <= 0) {
				this.listVoiceFiles();
			}
		},

		onResponsiveInverted() {
			if (window.innerWidth < 768) {
				this.responsive = true;
			} else {
				this.responsive = false;
			}
		},
	},

	mounted() {
		this.getData();
	},
};
</script>
<style>
.md-table table {
	overflow: visible !important;
}
</style>
