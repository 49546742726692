<template>
	<div class="content">
		<div class="md-layout-item">
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label for="movie"> Status</label>
						<md-select name="movie" id="movie">
							<md-option value="fight-club">All Status</md-option>
							<md-option value="godfather">Successful</md-option>
							<md-option value="godfather-ii">Failed</md-option>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item">
					<!-- <label for="movies">Start Date</label> -->
					<md-datepicker v-model="selectedClose" md-immediately />
				</div>
				<div class="md-layout-item">
					<md-button class="md-success">Search</md-button>
				</div>
			</div>
		</div>
		<div class="md-layout">
			<Table title="Payments Report">
				<md-table v-model="tableData">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.id }}</md-table-cell>
						<md-table-cell md-label="Payment Date ">{{
							item.created_at
						}}</md-table-cell>
						<md-table-cell md-label="Amount Paid ">{{
							item.amount
						}}</md-table-cell>
						<md-table-cell md-label=" Status ">{{ item.status }}</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";

export default {
	components: {
		Table,
	},
	data() {
		return {
			tableData: [
				{
					id: 1,
					amount: "10000",
					created_at: "10/09/2022",
					status: "successful",
				},
				{
					id: 2,
					amount: "40000",
					created_at: "10/09/2022",
					status: "successful",
				},
				{
					id: 3,
					amount: "30000",
					created_at: "10/09/2022",
					status: "failed",
				},
				{
					id: 4,
					amount: "90000",
					created_at: "10/09/2022",
					status: "failed",
				},
				{
					id: 5,
					amount: "100000",
					created_at: "10/09/2022",
					status: "successful",
				},
			],
		};
	},
};
</script>
