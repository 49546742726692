<template>
	<div class="content">
		<div class="md-layout">
		
			<Table title="Contacts" :class="{ 'md-size-70': allow_edit }">
				<md-table v-model="contacts">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.id }}</md-table-cell>
						<md-table-cell md-label="Full Name">{{
							item.firstname + " " + item.lastname
						}}</md-table-cell>
						<md-table-cell md-label="Phone">{{ item.mobileno }}</md-table-cell>
						<md-table-cell md-label="BirthDay">{{
							getBirthDay(item.birthday)
						}}</md-table-cell>
						<md-table-cell md-label="Anniversary">{{
							getBirthDay(item.anniversary)
						}}</md-table-cell>
						<md-table-cell md-label="Contact List Name">{{
							item.listname
						}}</md-table-cell>
						<md-table-cell md-label="Actions">
							<md-button class="md-just-icon md-info"
								><md-icon>person</md-icon></md-button
							>
							<md-button
								@click.prevent="editContact(item)"
								class="md-just-icon md-success"
								><md-icon>edit</md-icon></md-button
							>
							<md-button
								@click.prevent="deleteContact(item)"
								class="md-just-icon md-danger"
								><md-icon>close</md-icon></md-button
							>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>
			

			<div
				v-if="allow_edit"
				class="md-layout-item"
				:class="{ 'md-medium-size-100 md-size-30': allow_edit }"
			>
				<form @submit.prevent="updateContact()" method="post">
					<md-card>
						<md-card-header class="md-card-header-icon md-card-header-green">
							<h4 class="title">Edit Contact</h4>
						</md-card-header>

						<md-card-content>
							<md-field>
								<label for="name"
									>Full Name
									<span class="text-danger" style="font-size: 10px"> * </span>
									<span class="text-danger" style="font-size: 10px"
										>(Eg. John Doe)</span
									></label
								>
								<md-input
									v-model="fullname"
									id="name"
									type="text"
									required
								></md-input>
							</md-field>
							<md-field>
								<label for="phone"
									>Phone Number<span
										class="text-danger"
										style="font-size: 10px"
									>
										*
									</span>
									<span class="text-danger text-sm" style="font-size: 10px"
										>(Eg. 08098786445)</span
									></label
								>
								<md-input
									v-model="form.mobileno"
									id="phone"
									type="tel"
									required
								></md-input>
							</md-field>
							<div class="md-layout-item">
								<md-datepicker
									v-model="form.birthday"
									style="margin-bottom: 0; padding-bottom: 0"
									id="birthday"
									md-immediately
								/>
								<div align="center" style="margin-top: 0px; padding-top: 0px">
									<label for="birthday" style="font-size: 12px">Birthday</label>
								</div>
							</div>
							<div class="md-layout-item">
								<md-datepicker
									v-model="form.anniversary"
									style="margin-bottom: 0; padding-bottom: 0"
									id="anniversary"
									md-immediately
								/>
								<div align="center" style="margin-top: 0px; padding-top: 0px">
									<label for="anniversary" style="font-size: 12px"
										>Anniversary</label
									>
								</div>
							</div>
							<md-field style="margin-top: 2.5rem">
								<label for="list"
									>Contact list<span
										class="text-danger"
										style="font-size: 10px"
									>
										*
									</span></label
								>
								<md-select v-model="form.listid" name="list" id="list">
									<md-option value="" selected>--select a list--</md-option>
									<md-option
										v-for="list in lists"
										:key="list.listid"
										:value="list.listid"
										>{{ list.listname }}</md-option
									>
								</md-select>
							</md-field>
						</md-card-content>

						<md-card-actions md-alignment="right">
							<md-button
								@click.prevent="allow_edit = false"
								class="md-default"
								type="reset"
								:disabled="isLoading"
								>Cancel</md-button
							>
							<md-button class="md-success" type="submit" :disabled="isLoading"
								>Update</md-button
							>
						</md-card-actions>
					</md-card>
				</form>
			</div>
		</div>
		


	</div>
	
</template>

<script>
import Table from "@/components/Tables/Default.vue";
import $ from "jquery";
import { mapGetters } from "vuex";

export default {
	components: {
		Table,
	},

	data() {
		return {
			allow_edit: false,
			contact: null,
			fullname: null,
			list_name: "",
			form: {
				mobileno: null,
				first_name: null,
				last_name: null,
				birthday: "",
				anniversary: "",
				listid: "",
				recordid: null,
			},
		};
	},

	computed: {
		...mapGetters({
			contacts: "contact/contacts",
			lists: "contact/contactList",
			isLoading: "shared/getLoading",
		}),
	},
	methods: {
		getBirthDay(brithday) {
			const date = new Date(brithday);
			const day = date.getDate();
			const month = date.getMonth() + 1;

			return isNaN(day) ? "Not avail." : `${day}/${month}`;
		},

		editContact(contact) {
			this.form.mobileno = contact.mobileno;
			this.fullname = `${contact.firstname} ${contact.lastname}`;
			this.form.first_name = contact.firstname;
			this.form.last_name = contact.lastname;
			this.form.listid = contact.listid;
			this.form.recordid = contact.id;

			let birthday = contact.birthday;
			let anniversary = contact.anniversary;

			birthday = birthday.split(" ")[0];
			anniversary = anniversary.split(" ")[0];

			this.form.birthday = birthday;
			this.form.anniversary = anniversary;

			$("#birthday > input").val(birthday);
			$("#anniversary > input").val(anniversary);

			this.allow_edit = true;
		},

		updateContact() {
			this.$store.dispatch("contact/editContact").then((response) => {
				this.allow_edit = false;
			});
		},

		deleteContact(contact) {
			Swal.fire({
				title: "Delete!",
				text: `Are you sure you want to delete ${contact.firstname} ${contact.lastname}?`,
				type: "warning",
				showCancelButton: true,
				confirmButtonClass: "md-button md-success",
				cancelButtonClass: "md-button md-danger",
				confirmButtonText: "Delete!",
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch("contact/deleteContact", this.form);
				}
			});
		},

		getData() {
			if (this.lists && this.lists.length <= 0) {
				this.$store.dispatch("contact/contactList");
			}
			if (this.contacts && this.contacts.length <= 0) {
				this.$store.dispatch("contact/contacts");
			}
		},
	},
	mounted() {
		this.getData();
	},
};
</script>
