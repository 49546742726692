<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Archived SMS List">
				<md-table v-model="tableData">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.id }}</md-table-cell>
						<md-table-cell md-label="Created Date ">{{
							item.created_at
						}}</md-table-cell>
						<md-table-cell md-label="Account Code ">{{
							item.account_code
						}}</md-table-cell>
						<md-table-cell md-label="Scheduled Date">{{
							item.scheduled_date
						}}</md-table-cell>
						<md-table-cell md-label="Scheduled Time">{{
							item.scheduled_time
						}}</md-table-cell>
						<md-table-cell md-label="Target List">{{
							item.target
						}}</md-table-cell>
						<md-table-cell md-label="Sender Name">{{
							"Preshnet"
						}}</md-table-cell>
						<md-table-cell md-label="Actions">
							<md-button class="md-just-icon md-success"
								><md-icon>edit</md-icon></md-button
							>
							<md-button class="md-just-icon md-danger"
								><md-icon>close</md-icon></md-button
							>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";

export default {
	components: {
		Table,
	},
	data() {
		return {
			message: null,
			tableData: [
				{
					id: 1,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "BAC",
				},
				{
					id: 2,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "AVS",
				},
				{
					id: 3,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "ABE",
				},
				{
					id: 4,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "ABC",
				},
			],
		};
	},
};
</script>
