<template>
	<div class="user">
		<div class="photo">
			<img :src="user_image" alt="avatar" />
		</div>
		<div class="user-info">
			<a
				data-toggle="collapse"
				:aria-expanded="!isClosed"
				@click.stop="toggleMenu"
				@click.capture="clicked"
			>
				<span>
					{{ user.firstname ? user.firstname : user.lastname }}
					<b class="caret"></b>
				</span>
			</a>

			<collapse-transition>
				<div v-show="!isClosed">
					<ul class="nav">
						<slot>
							<li>
								<a href="/profile">
									<span class="sidebar-mini">MP</span>
									<span class="sidebar-normal">My Profile</span>
								</a>
							</li>
							<!-- <li>
								<a href="#vue">
									<span class="sidebar-mini">EP</span>
									<span class="sidebar-normal">Edit Profile</span>
								</a>
							</li> -->
							<li>
								<a href="#vue">
									<span class="sidebar-mini">S</span>
									<span class="sidebar-normal">Settings</span>
								</a>
							</li>
						</slot>
					</ul>
				</div>
			</collapse-transition>
		</div>
	</div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
	components: {
		CollapseTransition,
	},
	props: {
		title: {
			type: String,
			default: "Tania Andrew",
		},
		avatar: {
			type: String,
			default: "./img/faces/avatar.jpg",
		},
	},
	computed: {
		...mapGetters({
			user: "auth/currentUser",
		}),
	},
	data() {
		return {
			isClosed: true,
			user_image: process.env.BASE_URL + "img/default-avatar.png",
		};
	},
	methods: {
		clicked: function (e) {
			e.preventDefault();
		},
		toggleMenu: function () {
			this.isClosed = !this.isClosed;
		},
	},
};
</script>
<style>
.collapsed {
	transition: opacity 1s;
}
</style>
