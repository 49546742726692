<template>
	<footer class="footer">
		<div class="container">
			<div class="copyright text-center float-right">
				&copy; {{ new Date().getFullYear() }}
				<a href="https://phreshnetng.com" target="_blank">PreshNet Nig.</a>
			</div>
		</div>
	</footer>
</template>
<script>
export default {};
</script>
<style></style>
