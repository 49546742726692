<template>
	<div class="md-layout text-center">
		<div
			class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
		>
			<form action="" method="post" @submit.prevent="authenticate()">
				<login-card header-color="green">
					<h3 slot="title" class="title">Please login</h3>

					<ErrorAlert slot="description"></ErrorAlert>

					<md-field class="md-form-group" slot="inputs">
						<md-icon>face</md-icon>
						<label>Username...</label>
						<md-input v-model="form.useremail" required></md-input>
					</md-field>
					<md-field class="md-form-group" slot="inputs">
						<md-icon>lock_outline</md-icon>
						<label>Password...</label>
						<md-input
							type="password"
							v-model="form.password"
							required
						></md-input>
					</md-field>
					<md-button
						slot="footer"
						class="md-simpler md-success md-lg"
						type="submit"
						:disabled="isLoading"
					>
						<template v-if="isLoading">Please wait</template>
						<template v-else>Lets Go</template>
					</md-button>
				</login-card>
			</form>
		</div>
	</div>
</template>
<script>
/* eslint-disable */
import { LoginCard } from "@/components";
import ErrorAlert from "@/components/Alerts/ErrorAlert";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		LoginCard,
		ErrorAlert,
	},
	data() {
		return {
			form: {
				useremail: null,
				password: null,
			},
		};
	},
	computed: {
		...mapGetters({
			isLoading: "shared/getLoading",
			isAuthenticated: "auth/authenticated",
		}),
	},
	methods: {
		...mapActions({
			login: "auth/Login",
		}),

		authenticate() {
			
			this.login(this.form).then((response) => {
				if (this.isAuthenticated) {
					this.$router
						.push("/dashboard")
						.then((resp) => location.reload())
						.catch((error) => {
							// console.info(error.message);
						});
				}
			});
		},
	},
};
</script>

<style></style>
