export const contacts = (state, payload) => {
  state.contacts = payload;
};

export const contactList = (state, payload) => {
  state.contactList = payload;
};

export const archivedList = (state, payload) => {
  state.archivedList = payload;
};

export const updateContactList = (state, payload) => {
  state.contactList.unshift(payload);
};

export const contactGroup = (state, payload) => {
  state.contactGroup = payload;
};

export const updateContactGroup = (state, payload) => {
  state.contactGroup.unshift(payload);
};

export const archivedGroup = (state, payload) => {
  state.archivedGroup = payload;
};
