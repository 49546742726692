<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Contact Archived Groups" class="md-size-66">
				<md-table v-model="groups">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.id }}</md-table-cell>
						<md-table-cell md-label="Group Name">{{
							item.groupname
						}}</md-table-cell>
						<md-table-cell md-label="Actions">
							<md-button
								style="width: 90px; min-width: unset; padding-top: 2px"
								@click.prevent="activateContactListGroup(item)"
								class="md-success"
								>Unarchive</md-button
							>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
	components: {
		Table,
	},
	data() {
		return {
			title: "Add List To Group",
			modal_title: "",
			btnTitle: "Save Group",
			responsive: false,
			showListModal: false,
			groupname: null,
			listIds: [],
		};
	},
	computed: {
		...mapGetters({
			groups: "contact/archivedGroup",
			isLoading: "shared/getLoading",
		}),
	},
	methods: {
		...mapActions({
			getArchivedGroup: "contact/getArchiveGroup",
			// deleteGroup: "contact/deleteGroup",
		}),

		activateContactListGroup(group) {
			Swal.fire({
				title: "Activate!",
				text: "Are you sure you want to activate " + group.groupname + "?",
				type: "warning",
				showCancelButton: true,
				confirmButtonClass: "md-button md-success",
				cancelButtonClass: "md-button md-default",
				confirmButtonText: "Yes, Proceed!",
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.activateList({ listid: list.listid });
				}
			});
		},

		getData() {
			this.getArchivedGroup();
		},
	},
	mounted() {
		this.getData();
	},
};
</script>
