export const setValidationErrors = ({ commit }, error) => {
  commit('setValidationErrors', error)
}

export const clearValidationErrors = ({ commit }) => {
  commit('setValidationErrors', [])
}

export const setPermissionError = ({ commit }) => {
  commit('setPermissionError')
}
export const setLoading = ({ commit }, status) => {
  commit('setIsLoading', status)
}

