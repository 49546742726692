import { updateMyProfile, getMyProfile, updateMyPassword } from '@/api/profile'
import store from '@/store'

export const updateProfile = ({ commit }, payload) => {
    
  return new Promise((resolve, reject) => {
    updateMyProfile(payload).then((response) => {
      if (response && response.statuscode === '200' && response.description === 'success' ) {
          getProfile(payload);
        }
        // resolve(response)
    }).catch((error) => {
    }) 
  })
}

export const getProfile = ({ commit }, payload) => {
    
  return new Promise((resolve, reject) => {
    getMyProfile(payload).then((response) => {
        if (response && response.length > 0) {
          store.commit("auth/setProfile", response[0]);
          
          resolve()
          // store.commit('shared/setSuccessResponse', 'Your profile was updated successfully!')
        }
    }).catch((error) => {
    }) 
  })
}


export const updatePassword = ({ commit }, payload) => {
    
  return new Promise((resolve, reject) => {
    updateMyPassword(payload).then((response) => {
        if (response && response.statuscode == '200' && response.description == 'Success') {
          store.commit("auth/logout");
        }
    }).catch((error) => {
    }) 
  })
}