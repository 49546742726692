import router from '@/router';
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

export const login = (state, payload) => {
  state.currentUser = payload
  setAuthenticated(state, true)
}

export const setAuthenticated = (state, type) => {
  state.authenticated = type
}

export const registerFailed = (state, error) => {
  // state.registerLoading = false
}

export const setProfile = (state, payload) => {
  state.currentUser.firstname = payload.firstname;
  state.currentUser.lastname = payload.lastname;
  state.currentUser.address = payload.address;
  state.currentUser.zipcode = payload.zipcode;
  state.currentUser.city = payload.city;
  state.currentUser.state = payload.state;
  state.currentUser.country = payload.country;
}

export const logout = (state) => {
  state.currentUser = null;
  ls.clear();
  setAuthenticated(state, false);

  router.push("/login");
}




