<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Contact List" class="md-size-66">
				<md-table v-model="lists">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.id }}</md-table-cell>
						<md-table-cell md-label="List Name">{{
							item.groupname
						}}</md-table-cell>
						<md-table-cell md-label="Total Contacts">{{
							item.totalcontacts
						}}</md-table-cell>
						<md-table-cell md-label="Actions">
							<md-button
								class="md-just-icon md-success"
								@click.prevent="editContactList(item)"
								><md-icon>edit</md-icon></md-button
							>
							<md-button
								class="md-just-icon md-info"
								@click.prevent="archiveContactList(item)"
								><md-icon>assignment</md-icon></md-button
							>
							<md-button
								@click.prevent="deleteContactList(item)"
								class="md-just-icon md-danger"
								><md-icon>close</md-icon></md-button
							>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>

			<div class="md-layout-item md-medium-size-100 md-size-33">
				<form @submit.prevent="saveList()" method="post">
					<md-card>
						<md-card-header class="md-card-header-icon md-card-header-green">
							<h4 class="title">{{ title }}</h4>
						</md-card-header>

						<md-card-content>
							<md-field>
								<label for="name">Enter List Name</label>
								<md-input
									v-model="form.listname"
									id="name"
									type="text"
									required
								></md-input>
							</md-field>
						</md-card-content>

						<md-card-actions md-alignment="right">
							<md-button
								class="md-success"
								type="submit"
								:disabled="isLoading"
								>{{ btnTitle }}</md-button
							>
						</md-card-actions>
					</md-card>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
	components: {
		Table,
	},
	data() {
		return {
			title: "Add New Contact List",
			btnTitle: "Save List",
			form: {
				listname: null,
				listid: null,
				// listall: 1,
			},
		};
	},
	computed: {
		...mapGetters({
			lists: "contact/contactList",
			isLoading: "shared/getLoading",
		}),
	},
	methods: {
		...mapActions({
			createList: "contact/createList",
			deleteList: "contact/deleteList",
			archiveList: "contact/archiveList",
		}),

		saveList() {
			this.form.listname = '"' + this.form.listname + '"';
			this.createList(this.form).then((response) => {
				if (response) {
					this.form.listname = null;
				}
			});
		},
		editContactList(list) {
			alert("No API for edit");
		},
		deleteContactList(list) {
			Swal.fire({
				title: "Delete!",
				text: "Are you sure you want to delete " + list.listname + "?",
				type: "warning",
				showCancelButton: true,
				confirmButtonClass: "md-button md-success",
				cancelButtonClass: "md-button md-danger",
				confirmButtonText: "Delete!",
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.deleteList({ listid: list.id });
				}
			});
		},
		archiveContactList(list) {
			Swal.fire({
				title: "Archive!",
				text: "Are you sure you want to archive " + list.listname + "?",
				type: "warning",
				showCancelButton: true,
				confirmButtonClass: "md-button md-success",
				cancelButtonClass: "md-button md-default",
				confirmButtonText: "Yes, Proceed!",
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.archiveList({ listid: list.id });
				}
			});
		},
	},
	mounted() {
		this.$store.dispatch("contact/contactListWithCount");
	},
};
</script>
