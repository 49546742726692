<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Archived Robocall List">
				<md-table v-model="robocalls">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.scheduleid }}</md-table-cell>
						<md-table-cell md-label="Created Date ">{{
							item.datacreated
						}}</md-table-cell>
						<md-table-cell md-label="Account Code ">{{
							item.accountcode
						}}</md-table-cell>
						<md-table-cell md-label="Scheduled Date">{{
							item.scheduledate
						}}</md-table-cell>
						<md-table-cell md-label="Card No.">{{
							item.cardnumber
						}}</md-table-cell>
						<md-table-cell md-label="Status">{{ item.status }}</md-table-cell>
						<!-- <md-table-cell md-label="Voice File">{{
							item.recording
						}}</md-table-cell> -->
						<md-table-cell md-label="Actions">
							<md-button class="md-just-icon md-success"
								><md-icon>edit</md-icon></md-button
							>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		Table,
	},

	computed: {
		...mapGetters({
			robocalls: "robocall/listArchivedRobocalls",
		}),
	},

	methods: {
		getArchives() {
			if (this.robocalls && this.robocalls.length) return;

			this.$store.dispatch("robocall/listArchivedRoboCalls");
		},
	},

	mounted() {
		this.getArchives();
	},
};
</script>
