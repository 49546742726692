import Request from '@/Request'


export function uploadVoiceFiles(payload) {
  return Request({
    url: '/uploadvoicefiles',
    method: 'post',
    data: payload
  })
}

export function listVoiceFiles(payload) {
  return Request({
    url: '/listrecordings',
    method: 'get',
    params:payload
  })
}