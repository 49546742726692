import { login } from "@/api/auth";
import store from "@/store";

// Handles user login
export const Login = ({ commit }, payload) => {
  //debugger;
  return new Promise((resolve, reject) => {
    login(payload)
      .then((response) => {
        if (response.statuscode == 200) {
          store.commit("auth/login", response);
        }
        resolve(response);
      })
      .catch((error) => {
        // reject(alert('Something went wrong, please try again'))
      });
  });
};

export const Register = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    register(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // alert('Something went wrong, please try again')
        // commit('registerFailed', error)
        // reject(error)
      });
  });
};
