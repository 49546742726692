<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Voice Recording List" class="md-size-66">
				<md-table v-model="recordings">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.voicetag }}</md-table-cell>
						<md-table-cell md-label="File Name ">{{
							item.filename
						}}</md-table-cell>
						<md-table-cell md-label="Actions">
							<md-button
								@click.prevent="playAudio(item.filepath, item.voicetag)"
								class="md-just-icon md-info"
								><i
									:class="
										audio && voicetag == item.voicetag
											? 'fa fa-stop'
											: 'fa fa-play'
									"
								></i
							></md-button>
							<md-button class="md-just-icon md-success"
								><md-icon>edit</md-icon></md-button
							>
							<md-button class="md-just-icon md-danger"
								><md-icon>close</md-icon></md-button
							>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>

			<div class="md-layout-item md-medium-size-100 md-size-33">
				<form @submit.prevent="uploadFile()" action="" method="post">
					<md-card>
						<md-card-header class="md-card-header-icon md-card-header-green">
							<h4 class="title">Upload New Recording</h4>
							<p class="text-info">
								<b>Only the following voice file format is supported:</b> <br />
								<strong class="text-danger"
									>(.mp3 .wav .m4a .aac .ogg .ac3 .amr)</strong
								>
							</p>
							<p class="text-info">
								<b>The maximum upload file size is </b>
								<strong class="text-danger">20MB</strong>
							</p>
						</md-card-header>
						<md-card-content>
							<hr />
							<div>
								<label for="file">Upload Voice File</label>
								<input
									type="file"
									@change="setSelectedFile()"
									name="file"
									id="file"
									ref="file"
									accept="audio/*"
									required
								/>
							</div>
						</md-card-content>

						<md-card-actions md-alignment="right">
							<md-button type="submit" :disabled="isLoading" class="md-success"
								>Upload File</md-button
							>
						</md-card-actions>
					</md-card>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		Table,
	},
	data() {
		return {
			file: null,
			audio: null,
			voicetag: null,
		};
	},
	computed: {
		...mapGetters({
			user: "auth/currentUser",
			isLoading: "shared/getLoading",
			recordings: "voiceRecording/voiceFiles",
		}),
	},
	methods: {
		uploadFile() {
			let fd = new FormData();
			fd.append("accountno", this.user.accountno);
			fd.append("file", this.file);

			this.$store
				.dispatch("voiceRecording/uploadVoiceFile", fd)
				.then((response) => {
					this.file = null;
					this.$refs.file.value = null;
				});
		},

		playAudio(audio_path, voicetag) {
			if (this.audio) {
				return this.stopAudio();
			}

			this.audio = new Audio(audio_path);
			this.audio.play();
			this.voicetag = voicetag;
		},

		stopAudio() {
			if (this.audio) {
				this.audio.pause();
				this.audio = null;
			}
		},

		setSelectedFile() {
			this.file = this.$refs.file.files[0];
		},
	},
	mounted() {
		this.$store.dispatch("voiceRecording/listFiles");
	},
};
</script>
