<template>
	<div
		class="wrapper"
		:class="[
			{ 'nav-open': $sidebar.showSidebar },
			{ rtl: $route.meta.rtlActive },
		]"
	>
		<!-- <notifications></notifications> -->

		<side-bar
			:active-color="sidebarBackground"
			:data-background-color="sidebarBackgroundColor"
		>
			<user-menu></user-menu>
			<mobile-menu></mobile-menu>
			<template slot="links">
				<sidebar-item
					:link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
				>
				</sidebar-item>
				<sidebar-item :link="{ name: 'Manage Contact List', icon: 'image' }">
					<sidebar-item :link="{ name: 'Contacts', disableCollapse: true }">
						<sidebar-item
							:link="{ name: 'View Contacts', path: '/contacts' }"
						/>
						<sidebar-item
							:link="{ name: 'Add New Contacts', path: '/contacts/new' }"
						/>
						<sidebar-item
							:link="{ name: 'View Extended Contacts', path: '/contacts/contactsext' }"
						/>
					</sidebar-item>

					<sidebar-item :link="{ name: 'Contact List', disableCollapse: true }">
						<sidebar-item
							:link="{ name: 'View List', path: '/contacts/list' }"
						/>
						<sidebar-item
							:link="{
								name: 'View Archived List',
								path: '/contacts/list/archived',
							}"
						/>
					</sidebar-item>
					<sidebar-item
						:link="{ name: 'Contact Group', disableCollapse: true }"
					>
						<sidebar-item
							:link="{ name: 'View Groups', path: '/contacts/groups' }"
						/>
						<sidebar-item
							:link="{
								name: 'View Archived Groups',
								path: '/contacts/groups/archives',
							}"
						/>
					</sidebar-item>
				</sidebar-item>
				<sidebar-item :link="{ name: 'Manage Contact Groups', icon: 'apps' }">
					<sidebar-item
							:link="{ name: 'View Groups', path: '/contacts/groups' }"
						/>
						<sidebar-item
							:link="{
								name: 'View Archived Groups',
								path: '/contacts/groups/archives',
							}"
						/>
					
				</sidebar-item>
				<sidebar-item :link="{ name: 'Manage Robocalls', icon: 'apps' }">
					<sidebar-item
						:link="{ name: 'Scheduled Robocalls', path: '/robocalls' }"
					></sidebar-item>
					<sidebar-item
						:link="{
							name: 'Archived Robocalls',
							path: '/robocalls/archive',
						}"
					></sidebar-item>
				</sidebar-item>
				<sidebar-item :link="{ name: 'Wakeup Calls', icon: 'phone' }">
					<sidebar-item
						:link="{ name: 'Scheduled Wakeup Calls', path: '/wakeupcalls' }"
					></sidebar-item>
					<sidebar-item
						:link="{
							name: 'Archived Wakeup Calls',
							path: '/wakeupcalls/archive',
						}"
					></sidebar-item>
				</sidebar-item>
				<sidebar-item :link="{ name: 'Manage SMS Text', icon: 'sms' }">
					<sidebar-item
						:link="{ name: 'Scheduled SMS', path: '/smsText' }"
					></sidebar-item>
					<sidebar-item
						:link="{ name: 'Archived SMS', path: '/smsText/archive' }"
					></sidebar-item>
				</sidebar-item>
				<sidebar-item :link="{ name: 'Recordings', icon: 'inbox' }">
					<sidebar-item
						:link="{ name: 'View Recordings', path: '/recordings' }"
					></sidebar-item>
					<sidebar-item
						:link="{ name: 'Archived Recordings', path: '/recordings/archive' }"
					></sidebar-item>
				</sidebar-item>
				<sidebar-item :link="{ name: 'Reports', icon: 'place' }">
					<sidebar-item
						:link="{ name: 'Calls', path: '/reports' }"
					></sidebar-item>
					<sidebar-item
						:link="{ name: 'SMS', path: '/reports/sms' }"
					></sidebar-item>
					<sidebar-item
						:link="{ name: 'Payments', path: '/reports/payments' }"
					></sidebar-item>
				</sidebar-item>
				<sidebar-item :link="{ name: 'Manage Services', icon: 'apps' }">
					<sidebar-item
							:link="{ name: 'Birthday Greetings', path: '#' }"
						/>
						<sidebar-item
							:link="{ name: 'Anniversary Greetings', path: '#' }"
						/>
						<sidebar-item
							:link="{ name: 'Prayer Lines', path: '#' }"
						/>
						<sidebar-item
							:link="{ name: 'Named Greetings', path: '#' }"
						/>
				</sidebar-item>
				<sidebar-item
          :link="{ name: 'Support', icon: 'date_range', path: '/reports' }"
				>
				</sidebar-item>
				<sidebar-item
					:link="{ name: 'Logout', icon: 'games', path: '#' }"
					@click.native="showSwal()"
				></sidebar-item>
			</template>
		</side-bar>
		<div class="main-panel">
			<top-navbar></top-navbar>

			<div
				:class="{ content: !$route.meta.hideContent }"
				@click="toggleSidebar"
			>
				<!-- Alerts content here -->
				<ErrorAlert></ErrorAlert>
				<SuccessAlert></SuccessAlert>

				<zoom-center-transition :duration="200" mode="out-in">
					<!-- your content here -->
					<router-view></router-view>
				</zoom-center-transition>
			</div>
			<content-footer v-if="!$route.meta.hideFooter"></content-footer>
		</div>
	</div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
		document.getElementsByClassName(className)[0].scrollTop = 0;
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

function reinitScrollbar() {
	let docClasses = document.body.classList;
	let isWindows = navigator.platform.startsWith("Win");
	if (isWindows) {
		// if we are on windows OS we activate the perfectScrollbar function
		initScrollbar("sidebar");
		initScrollbar("sidebar-wrapper");
		initScrollbar("main-panel");

		docClasses.add("perfect-scrollbar-on");
	} else {
		docClasses.add("perfect-scrollbar-off");
	}
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import ErrorAlert from "@/components/Alerts/ErrorAlert";
import SuccessAlert from "@/components/Alerts/SuccessAlert";
import Swal from "sweetalert2";

export default {
	components: {
		TopNavbar,
		ContentFooter,
		// FixedPlugin,
		MobileMenu,
		UserMenu,
		ZoomCenterTransition,
		SuccessAlert,
		ErrorAlert,
	},
	data() {
		return {
			sidebarBackgroundColor: "black",
			sidebarBackground: "green",
			sidebarMini: true,
			sidebarImg: true,
		};
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		},
		minimizeSidebar() {
			if (this.$sidebar) {
				this.$sidebar.toggleMinimize();
			}
		},

		showSwal() {
			Swal.fire({
				title: "Logout!",
				text: "Are you sure you want to logout?",
				type: "warning",
				showCancelButton: true,
				confirmButtonClass: "md-button md-success",
				cancelButtonClass: "md-button md-danger",
				confirmButtonText: "Yes, Log Me Out!",
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.$store.commit("auth/logout");
					// this.$router.push("/login");
				}
			});
		},
	},
	updated() {
		reinitScrollbar();
	},
	mounted() {
		reinitScrollbar();
	},
	watch: {
		sidebarMini() {
			this.minimizeSidebar();
		},
	},
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
	from {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
	to {
		opacity: 1;
	}
}
.main-panel .zoomIn {
	animation-name: zoomIn95;
}
@keyframes zoomOut95 {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
}
.main-panel .zoomOut {
	animation-name: zoomOut95;
}
</style>
