<template>
	<div>
		<!-- <md-card class="md-card-profile">
			<div class="md-card-avatar">
				<img class="img" :src="cardUserImage" />
			</div>

			<md-card-content>
				<h6 class="category text-gray">CEO / Co-Founder</h6>
				<h4 class="card-title">Alec Thompson</h4>
				<p class="card-description">
					Don't be scared of the truth because we need to restart the human
					foundation in truth And I love you like Kanye loves Kanye I love Rick
					Owens’ bed design but the back is...
				</p>
			</md-card-content>
		</md-card> -->

		<form @submit.prevent="updatePassword()" action="" method="post">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>person</md-icon>
					</div>
					<h4 class="title">Edit Password</h4>
				</md-card-header>
				<md-card-content>
					<div v-if="errorMsg" class="alert alert-rose">
						<strong>{{ errorMsg }}</strong>

						<button
							@click="errorMsg = null"
							type="button"
							aria-hidden="false"
							class="close"
						>
							×
						</button>
					</div>
					<md-field>
						<label for="name">Current Password</label>
						<md-input
							id="name"
							v-model="form.curpassword"
							type="password"
							required
						></md-input>
					</md-field>
					<md-field>
						<label for="name">New Password</label>
						<md-input
							id="name"
							v-model="confirm_password"
							type="password"
							required
						></md-input>
					</md-field>
					<md-field>
						<label for="name">Confirm New Password</label>
						<md-input
							id="name"
							v-model="form.newpassword"
							type="password"
							required
						></md-input>
					</md-field>
				</md-card-content>

				<div class="md-layout-item md-size-100 text-right">
					<md-button
						class="md-raised md-success mt-2"
						type="submit"
						style="margin-bottom: 1.3rem"
						:disabled="isLoading"
						>Update Profile</md-button
					>
				</div>
			</md-card>
		</form>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	name: "user-card",
	props: {
		cardUserImage: {
			type: String,
			default: "./img/faces/marc.jpg",
		},
		buttonColor: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			errorMsg: null,
			confirm_password: null,
			form: {
				curpassword: null,
				newpassword: null,
			},
		};
	},
	computed: {
		...mapGetters({
			isLoading: "shared/getLoading",
		}),
	},
	methods: {
		updatePassword() {
			if (!this.validateInput()) {
				return;
			}

			this.$store
				.dispatch("profile/updatePassword", this.form)
				.then((response) => this.resetForm());
		},

		validateInput() {
			if (this.form.newpassword !== this.confirm_password) {
				this.showError(
					"Your new password does not match your password confirmation!"
				);
				return false;
			}

			if (this.form.curpassword == null) {
				this.showError("Please enter your current password!");
				return false;
			}

			return true;
		},

		resetForm() {
			this.confirm_password = null;
			this.form.curpassword = null;
			this.form.newpassword = null;
		},

		showError(msg) {
			return (this.errorMsg = msg);
		},

		getColorButton: function (buttonColor) {
			return "md-" + buttonColor + "";
		},
	},
};
</script>
<style></style>
