var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[
			{ 'nav-open': _vm.$sidebar.showSidebar },
			{ rtl: _vm.$route.meta.rtlActive } ]},[_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Manage Contact List', icon: 'image' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Contacts', disableCollapse: true }}},[_c('sidebar-item',{attrs:{"link":{ name: 'View Contacts', path: '/contacts' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Add New Contacts', path: '/contacts/new' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Extended Contacts', path: '/contacts/contactsext' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Contact List', disableCollapse: true }}},[_c('sidebar-item',{attrs:{"link":{ name: 'View List', path: '/contacts/list' }}}),_c('sidebar-item',{attrs:{"link":{
								name: 'View Archived List',
								path: '/contacts/list/archived',
							}}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Contact Group', disableCollapse: true }}},[_c('sidebar-item',{attrs:{"link":{ name: 'View Groups', path: '/contacts/groups' }}}),_c('sidebar-item',{attrs:{"link":{
								name: 'View Archived Groups',
								path: '/contacts/groups/archives',
							}}})],1)],1),_c('sidebar-item',{attrs:{"link":{ name: 'Manage Contact Groups', icon: 'apps' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'View Groups', path: '/contacts/groups' }}}),_c('sidebar-item',{attrs:{"link":{
								name: 'View Archived Groups',
								path: '/contacts/groups/archives',
							}}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Manage Robocalls', icon: 'apps' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Scheduled Robocalls', path: '/robocalls' }}}),_c('sidebar-item',{attrs:{"link":{
							name: 'Archived Robocalls',
							path: '/robocalls/archive',
						}}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Wakeup Calls', icon: 'phone' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Scheduled Wakeup Calls', path: '/wakeupcalls' }}}),_c('sidebar-item',{attrs:{"link":{
							name: 'Archived Wakeup Calls',
							path: '/wakeupcalls/archive',
						}}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Manage SMS Text', icon: 'sms' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Scheduled SMS', path: '/smsText' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Archived SMS', path: '/smsText/archive' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Recordings', icon: 'inbox' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'View Recordings', path: '/recordings' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Archived Recordings', path: '/recordings/archive' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Reports', icon: 'place' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Calls', path: '/reports' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'SMS', path: '/reports/sms' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Payments', path: '/reports/payments' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Manage Services', icon: 'apps' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Birthday Greetings', path: '#' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Anniversary Greetings', path: '#' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Prayer Lines', path: '#' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Named Greetings', path: '#' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Support', icon: 'date_range', path: '/reports' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Logout', icon: 'games', path: '#' }},nativeOn:{"click":function($event){return _vm.showSwal()}}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('ErrorAlert'),_c('SuccessAlert'),_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }