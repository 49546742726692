<template>
	<form>
		<md-card>
			<md-card-header
				class="md-card-header-icon"
				:class="getClass(headerColor)"
			>
				<div class="card-icon">
					<md-icon>perm_identity</md-icon>
				</div>
				<h4 class="title">Edit Profile</h4>
			</md-card-header>

			<md-card-content>
				<form @submit.prevent="editProfile()" action="" method="post">
					<div class="md-layout">
						<div class="md-layout-item md-small-size-100 md-size-33">
							<md-field>
								<label>First Name</label>
								<md-input v-model="form.firstname" type="text"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-size-33">
							<md-field>
								<label>Last Name</label>
								<md-input v-model="form.lastname" type="text"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-size-33">
							<md-field>
								<label>Email Address</label>
								<md-input v-model="email" type="email" disabled></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-size-33">
							<md-field>
								<label>Phone Number</label>
								<md-input v-model="mobileno" type="text" disabled></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-size-33">
							<md-field>
								<label>Account Number</label>
								<md-input v-model="accountno" type="text" disabled></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-size-33">
							<md-field>
								<label>Address</label>
								<md-input v-model="form.address" type="text"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-size-25">
							<md-field>
								<label>Postal Code</label>
								<md-input v-model="form.zipcode" type="number"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-size-25">
							<md-field>
								<label>City</label>
								<md-input v-model="form.city" type="text"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-size-25">
							<md-field>
								<label>State</label>
								<md-input v-model="form.state" type="text"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-size-25">
							<md-field>
								<label>Country</label>
								<md-input v-model="form.country" type="text"></md-input>
							</md-field>
						</div>

						<!-- <div class="md-layout-item md-size-100">
							<md-field maxlength="5">
								<label>About Me</label>
								<md-textarea v-model="aboutme"></md-textarea>
							</md-field>
						</div> -->
						<div class="md-layout-item md-size-100 text-right">
							<md-button
								type="submit"
								class="md-raised md-success mt-4"
								:disabled="isLoading"
								>Update Profile</md-button
							>
						</div>
					</div>
				</form>
			</md-card-content>
		</md-card>
	</form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
	name: "edit-profile-form",
	props: {
		headerColor: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			username: null,
			disabled: null,
			emailadress: null,
			email: null,
			mobileno: null,
			accountno: null,
			form: {
				lastname: null,
				firstname: null,
				address: null,
				city: null,
				country: null,
				zipcode: null,
			},
		};
	},

	computed: {
		...mapGetters({
			user: "auth/currentUser",
			isLoading: "shared/getLoading",
		}),
	},

	methods: {
		...mapActions({
			updateProfile: "profile/updateProfile",
		}),

		editProfile() {
			let payload = {
				// token: this.user.token,
				profile: "'" + JSON.stringify([this.form]) + "'",
			};

			this.updateProfile(payload);
		},

		setProfileData() {
			if (!this.user) {
				return;
			}
			this.email = this.user.email;
			this.mobileno = this.user.mobileno;
			this.accountno = this.user.accountno;

			this.form.firstname = this.user.firstname;
			this.form.lastname = this.user.lastname;
			this.form.address = this.user.address;
			this.form.zipcode = this.user.zipcode;
			this.form.city = this.user.city;
			this.form.state = this.user.state;
			this.form.country = this.user.country;
		},

		getProfile() {
			this.$store
				.dispatch("profile/getProfile")
				.then((response) => this.setProfileData());
		},

		getClass: function (headerColor) {
			return "md-card-header-" + headerColor + "";
		},
	},

	mounted() {
		this.getProfile();
	},
};
</script>
<style></style>
