export const setLoading = (state, type) => {
  state.isLoading = type
}

export const setErrorResponse = (state, msg) => {
  state.errorResponse = msg
}

export const setSuccessResponse = (state, msg) => {
  state.successResponse = msg
}