<template>
  <div class="content">
    <div class="md-layout">
      <Table title="Contact Groups" class="md-size-66">
        <md-table v-model="groups">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="#">{{ item.id }}</md-table-cell>
            <md-table-cell md-label="Group Name">{{
              item.groupname
            }}</md-table-cell>
            <md-table-cell md-label="Total List">{{
              item.count
            }}</md-table-cell>
            <md-table-cell md-label="Actions">
              <drop-down>
                <md-button
                  slot="title"
                  class="md-button md-success md-round dropdown-toggle"
                  data-toggle="dropdown"
                  style="width: 90px; min-width: unset; padding-top: 2px"
                >
                  Choose
                </md-button>
                <ul
                  class="dropdown-menu"
                  :class="{ 'dropdown-menu-right': !responsive }"
                >
                  <li>
                    <a @click.prevent="showAddListToGroupModal(item)" href="#"
                      >Add New List</a
                    >
                  </li>
                  <li><a href="#">View List</a></li>
                  <li>
                    <a @click.prevent="editContactGroup(item)" href="#"
                      >Edit Group</a
                    >
                  </li>
                  <li>
                    <a @click.prevent="archiveContactGroup(item)" href="#"
                      >Archive Group</a
                    >
                  </li>
                  <li>
                    <a @click.prevent="deleteContactGroup(item)" href="#"
                      >Delete Group</a
                    >
                  </li>
                </ul>
              </drop-down>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </Table>

      <div class="md-layout-item md-medium-size-100 md-size-33">
        <form @submit.prevent="saveGroup()" method="post">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <h4 class="title">{{ title }}</h4>
            </md-card-header>

            <md-card-content>
              <md-field>
                <label for="name">Enter Group Name</label>
                <md-input
                  v-model="groupname"
                  id="name"
                  type="text"
                  required
                ></md-input>
              </md-field>
              <div class="md-layout-item">
                <md-field>
                  <label for="list">Choose Contact List To Add To Group</label>
                  <md-select v-model="listIds" name="list" id="list" multiple>
                    <md-option value="" selected>--choose list--</md-option>
                    <md-option
                      v-for="list in lists"
                      :key="list.listid"
                      :value="list.listid"
                      >{{ list.listname }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
            </md-card-content>

            <md-card-actions md-alignment="right">
              <md-button
                class="md-success"
                type="submit"
                :disabled="isLoading"
                >{{ btnTitle }}</md-button
              >
            </md-card-actions>
          </md-card>
        </form>
      </div>
    </div>
    <Modal :isVisible="showListModal">
      <h4 slot="header" class="title">{{ modal_title }}</h4>
      <form slot="body" @submit.prevent="saveGroup()" method="post">
        <div class="md-layout-item">
          <md-field>
            <label for="list">Choose Contact List To Add To Group</label>
            <md-select v-model="listIds" name="list" id="list" multiple>
              <md-option value="" selected>--choose list--</md-option>
              <md-option
                v-for="list in lists"
                :key="list.listid"
                :value="list.listid"
                >{{ list.listname }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </form>
      <div slot="footer">
        <md-button
          @click.prevent="showListModal = false"
          class="md-danger"
          style="margin-right: 5px"
          :disabled="isLoading"
        >
          Close
        </md-button>
        <md-button class="md-success" type="submit" :disabled="isLoading">{{
          btnTitle
        }}</md-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  components: {
    Table,
    Modal,
  },
  data() {
    return {
      title: "Add List To Group",
      modal_title: "",
      btnTitle: "Save Group",
      responsive: false,
      showListModal: false,
      groupname: null,
      listIds: [],
    };
  },
  computed: {
    ...mapGetters({
      groups: "contact/contactGroup",
      lists: "contact/contactList",
      isLoading: "shared/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      createGroup: "contact/createGroup",
      contactList: "contact/contactList",
      groupList: "contact/contactGroups",
      addListToGroup: "contact/addListToGroup",
      archiveGroup: "contact/archiveGroup",
      // deleteGroup: "contact/deleteGroup",
    }),

    formatFormData(groupid) {
      if (this.listIds.length <= 0) {
        return;
      }

      let params = [];

      this.listIds.forEach((id) => {
        params.push({ listid: id });
      });

      return {
        groupid: groupid,
        list: this.stringifyArray(params), //"'" + JSON.stringify(params) + "'",
      };
    },

    async saveGroup() {
      let params = { groupname: JSON.stringify(this.groupname) };
      await this.createGroup(params).then((response) => {
        if (response && response.groupid) {
          this.groupname = null;
          this.$store.commit(
            "shared/setSuccessResponse",
            "Adding list to group, please wait..."
          );

          this.addListToGroup(this.formatFormData(response.groupid)).then(
            (response) => {
              this.$store.commit(
                "shared/setSuccessResponse",
                "List was successfully added to group"
              );

              this.listIds = [];
            }
          );
        }
      });
    },

    editContactGroup(group) {
      alert("No API for edit");
    },

    deleteContactGroup(group) {
      Swal.fire({
        title: "Delete!",
        text: "Are you sure you want to delete " + group.groupname + "?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Delete!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteGroup({ listid: group.listid });
        }
      });
    },
    archiveContactGroup(group) {
      Swal.fire({
        title: "Archive!",
        text:
          "Are you sure you want to archive " +
          this.verifyString(group.groupname) +
          "?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-default",
        confirmButtonText: "Yes, Proceed!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.archiveGroup({
            groupid: this.stringifyArray([{ groupid: group.id }]),
          });
        }
      });
    },

    showAddListToGroupModal(group) {
      this.modal_title = `Add List To ${this.verifyString(group.groupname)} `;
      this.showListModal = true;
    },

    verifyString(val) {
      let text = val.toLowerCase();
      return text.includes("group") ? val : `${val} Group`;
    },

    stringifyArray(params) {
      return "'" + JSON.stringify(params) + "'";
    },

    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },

    getData() {
      this.groupList();
      this.contactList();
      this.onResponsiveInverted();
      window.addEventListener("resize", this.onResponsiveInverted);
    },
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
};
</script>
