<template>
	<div class="md-layout-item md-medium-size-100">
		<md-card>
			<md-card-header class="md-card-header-icon md-card-header-green">
				<div class="card-icon">
					<md-icon>assignment</md-icon>
				</div>
				<h4 class="title">{{ title }}</h4>
			</md-card-header>
			<md-card-content>
				<slot></slot>
			</md-card-content>
		</md-card>
	</div>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.text-right .md-table-cell-container {
	display: flex;
	justify-content: flex-end;
}
.md-table .md-table-head:last-child {
	text-align: right;
}

.table-stats {
	display: flex;
	align-items: center;
	text-align: right;
	flex-flow: row wrap;

	.td-price .td-total {
		display: inline-flex;
		font-weight: 500;
		font-size: 1.0625rem;
		margin-right: 50px;
	}

	&.table-striped .td-price {
		border-bottom: 0;
	}

	.td-price {
		font-size: 26px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	.td-price,
	> div {
		flex: 0 0 100%;
		padding: 12px 8px;
	}
}

.table-shopping .md-table-head:nth-child(5),
.table-shopping .md-table-head:nth-child(7),
.table-shopping .md-table-head:nth-child(6) {
	text-align: right;
}
</style>
