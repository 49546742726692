<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-50">
        <form action="" method="post">
          <md-card>
            <md-card-content>
              <div class="alert alert-primary">
                <strong
                  >Please select a list before you add or upload
                  contacts.</strong
                >
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label for="list"
                      >Choose a contact list<span
                        class="text-danger"
                        style="font-size: 10px"
                      >
                        *
                      </span></label
                    >
                    <md-select v-model="form.listid" name="list" id="list">
                      <md-option value="" selected>--select a list--</md-option>
                      <md-option
                        v-for="list in lists"
                        :key="list.listid"
                        :value="list.listid"
                        >{{ list.listname }}</md-option
                      >
                    </md-select>
                  </md-field>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>

      <div
        v-if="form.listid"
        class="md-layout-item md-medium-size-100 md-size-50"
      >
        <form @submit.prevent="uploadContactFromFile()" action="" method="post">
          <md-card>
            <md-card-header
              class="md-card-header-icon md-card-header-green"
              style="height: 20px"
            >
              <h4 class="title">Upload Contacts</h4>
              <hr />
            </md-card-header>

            <md-card-content style="margin-top: 20px">
              <label for="uploadfile" class="text-danger"
                >Accepted formats (excel,csv,txt)</label
              >
              <div>
                <input
                  @change="uploadFile"
                  ref="uploadfile"
                  id="uploadfile"
                  type="file"
                />
              </div>
              <div align="right">
                <md-button
                  type="submit"
                  :disabled="isLoading"
                  class="md-success"
                  >Upload</md-button
                >
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>

      <div
        v-show="form.listid"
        class="md-layout-item md-medium-size-100 md-size-100"
      >
        <div class="content" style="">
          <div class="md-layout">
            <div class="md-layout-item md-medium-size-100">
              <div class="md-card md-theme-default">
                <div
                  class="md-card-header md-card-header-icon md-card-header-green"
                >
                  <div class="card-icon">
                    <i class="md-icon md-icon-font md-theme-default"
                      >assignment</i
                    >
                  </div>
                  <h4 class="title">Add Contacts</h4>
                </div>
                <div class="md-card-content">
                  <div class="md-content md-table md-theme-default">
                    <div
                      class="md-content md-table-content md-scrollbar md-theme-default"
                    >
                      <Table title="">
                        <md-table v-model="contacts">
                          <md-table-row
                            slot="md-table-row"
                            slot-scope="{ item }"
                          >
                            <md-table-cell md-label="#">{{
                              item.id
                            }}</md-table-cell>
                            <md-table-cell md-label="Full Name">{{
                              item.first_name + " " + item.last_name
                            }}</md-table-cell>
                            <md-table-cell md-label="Phone">{{
                              item.mobileno
                            }}</md-table-cell>
                            <md-table-cell md-label="BirthDay">{{
                              item.birthday
                            }}</md-table-cell>
                            <md-table-cell md-label="Anniversary">{{
                              item.anniversary
                            }}</md-table-cell>
                            <md-table-cell md-label="Contact List Name">{{
                              item.listname
                            }}</md-table-cell>
                            <md-table-cell md-label="Actions">
                              <md-button class="md-just-icon md-info"
                                ><md-icon>person</md-icon></md-button
                              >
                              <md-button class="md-just-icon md-success"
                                ><md-icon>edit</md-icon></md-button
                              >
                              <md-button class="md-just-icon md-danger"
                                ><md-icon>close</md-icon></md-button
                              >
                            </md-table-cell>
                          </md-table-row>
                        </md-table>
                      </Table>
                      <hr />
                      <form @submit.prevent="saveContact()" action="">
                        <div class="md-layout">
                          <div class="md-layout-item md-size-20">
                            <md-field>
                              <label for="name"
                                >Full Name
                                <span
                                  class="text-danger"
                                  style="font-size: 10px"
                                >
                                  *
                                </span>
                                <span
                                  class="text-danger"
                                  style="font-size: 10px"
                                  >(Eg. John Doe)</span
                                ></label
                              >
                              <md-input
                                v-model="fullname"
                                id="name"
                                type="text"
                                required
                              ></md-input>
                            </md-field>
                          </div>
                          <div class="md-layout-item md-size-20">
                            <md-field>
                              <label for="phone"
                                >Phone Number<span
                                  class="text-danger"
                                  style="font-size: 10px"
                                >
                                  *
                                </span>
                                <span
                                  class="text-danger text-sm"
                                  style="font-size: 10px"
                                  >(Eg. 08098786445)</span
                                ></label
                              >
                              <md-input
                                v-model="form.mobileno"
                                id="phone"
                                type="tel"
                                required
                              ></md-input>
                            </md-field>
                          </div>
                          <div class="md-layout-item md-size-20">
                            <md-datepicker
                              v-model="form.birthday"
                              style="margin-bottom: 0; padding-bottom: 0"
                              id="birthday"
                              md-immediately
                            />
                            <div
                              align="center"
                              style="margin-top: 0px; padding-top: 0px"
                            >
                              <label for="birthday" style="font-size: 12px"
                                >Birth Day</label
                              >
                            </div>
                          </div>
                          <div class="md-layout-item md-size-25">
                            <md-datepicker
                              v-model="form.anniversary"
                              style="margin-bottom: 0; padding-bottom: 0"
                              id="anniversary"
                              md-immediately
                            />
                            <div
                              align="center"
                              style="margin-top: 0px; padding-top: 0px"
                            >
                              <label for="anniversary" style="font-size: 12px"
                                >Anniversary</label
                              >
                            </div>
                          </div>
                          <div class="md-layout-item md-size-15">
                            <md-button
                              type="submit"
                              class="md-primary"
                              :disabled="isLoading"
                              >Add Contact</md-button
                            >
                          </div>
                        </div>
                      </form>
                      <hr />
                    </div>
                    <!---->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      contacts: [],
      fullname: null,
      list_name: "",
      file: null,
      form: {
        mobileno: null,
        first_name: null,
        last_name: null,
        birthday: "",
        anniversary: "",
        listid: "",
      },
    };
  },

  watch: {
    "form.listid"(newVal, oldVal) {
      let list = this.lists.find((list) => list.listid == newVal);

      if (list) {
        this.list_name = list.listname;
      } else {
        this.list_name = "Unknown";
      }
    },
  },

  computed: {
    ...mapGetters({
      lists: "contact/contactList",
      isLoading: "shared/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      contactList: "contact/contactList",
      addContact: "contact/addContact",
      uploadContacts: "contact/uploadContacts",
    }),

    saveContact() {
      let fullname = this.fullname.split(" ");
      this.form.first_name = fullname[0];
      this.form.last_name = fullname[1] ?? "";

      this.addContact(this.form).then((response) => {
        if (response) {
          this.addToArray(response.recordid);
        }
      });
    },

    addToArray(record_id) {
      let contact = {
        id: record_id,
        listname: this.list_name,
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        mobileno: this.form.mobileno,
        birthday: this.form.birthday,
        anniversary: this.form.anniversary,
      };

      this.contacts.push(contact);

      this.clearFormInputs();
    },

    uploadFile() {
      this.file = this.$refs.uploadfile.files[0];
    },

    uploadContactFromFile() {
      let fd = new FormData();
      fd.append("listid", this.form.listid);
      fd.append("uploadfile", this.file);

      this.uploadContacts(fd);
    },

    clearFormInputs() {
      this.fullname = null;
      this.form.first_name = null;
      this.form.last_name = null;
      this.form.mobileno = null;
      // this.form.birthday = "";
      // this.form.anniversary = "";
    },

    stringifyArray(params) {
      return "'" + JSON.stringify(params) + "'";
    },

    range(min, max) {
      return [...Array(max - min + 1).keys()].map((i) => i + min);
    },
  },
  mounted() {
    this.contactList();
  },
};
</script>
