import Vue from "vue";
import router from './router';
import store from './store';
import DashboardPlugin from "./material-dashboard";
import SmartTable from 'vuejs-smart-table';

// Plugins
import App from "./App.vue";
import Chartist from "chartist";

// plugin setup
// Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(SmartTable);

require('./Request');

// global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store
});
