<template>
	<div class="content">
		<div class="md-layout-item">
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label>Phone Number</label>
						<md-input type="text"></md-input>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="movie">Phone Match</label>
						<md-select name="movie" id="movie">
							<md-option value="fight-club">Exact</md-option>
							<md-option value="godfather">Begins With</md-option>
							<md-option value="godfather-ii">Contains</md-option>
							<md-option value="godfather-iii">Ends With</md-option>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="movie">Call Status</label>
						<md-select name="movie" id="movie">
							<md-option value="fight-club">All Calls</md-option>
							<md-option value="godfather">Missed</md-option>
							<md-option value="godfather-ii">Received</md-option>
							<md-option value="godfather-iii">Rejected</md-option>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="movies">Duration</label>
						<md-select name="movies" id="movies" multiple>
							<md-option value="fight-club">Minutes</md-option>
							<md-option value="godfather">Seconds</md-option>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item">
					<!-- <label for="movies">Start Date</label> -->
					<md-datepicker v-model="selectedClose" md-immediately />
				</div>
				<div class="md-layout-item">
					<md-button class="md-success">Search</md-button>
				</div>
			</div>
		</div>
		<div class="md-layout">
			<Table title="Calls Report">
				<md-table v-model="tableData">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.id }}</md-table-cell>
						<md-table-cell md-label="Phone Number ">{{
							item.phone
						}}</md-table-cell>
						<md-table-cell md-label="Call Date ">{{
							item.created_at
						}}</md-table-cell>
						<md-table-cell md-label="Call Duration ">{{
							item.duration
						}}</md-table-cell>
						<md-table-cell md-label="Call Status ">{{
							item.status
						}}</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";

export default {
	components: {
		Table,
	},
	data() {
		return {
			tableData: [
				{
					id: 1,
					phone: "090938838383",
					created_at: "10/09/2022",
					duration: "01:00",
					status: "answered",
				},
				{
					id: 1,
					phone: "090938838383",
					created_at: "10/09/2022",
					duration: "03:00",
					status: "answered",
				},
				{
					id: 1,
					phone: "090938838383",
					created_at: "10/09/2022",
					duration: "04:00",
					status: "missed",
				},
				{
					id: 1,
					phone: "090938838383",
					created_at: "10/09/2022",
					duration: "11:00",
					status: "answered",
				},
				{
					id: 1,
					phone: "090938838383",
					created_at: "10/09/2022",
					duration: "00:00",
					status: "rejected",
				},
			],
		};
	},
};
</script>
