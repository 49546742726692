import Vue from "vue";
import Vuex from "vuex";
import state from "./modules/errors/state";
import * as actions from "./modules/errors/actions";
import createPersistedState from "vuex-persistedstate";
import * as mutations from "./modules/errors/mutations";
import * as getters from "./modules/errors/getters";
import auth from "@/store/modules/auth";
import contact from "@/store/modules/contact";
import dashboard from "@/store/modules/dashboard";
import profile from "@/store/modules/profile";
import report from "@/store/modules/report";
import robocall from "@/store/modules/robocall";
import sms from "@/store/modules/sms";
import voiceRecording from "@/store/modules/voiceRecording";
import wakeupCall from "@/store/modules/wakeupCall";
import shared from "@/store/modules/shared";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  //   plugins: [createPersistedState({
  //     paths: ['auth']
  //   }),
  // ],
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
      paths: ["auth"],
    }),
  ],
  state,
  actions,
  mutations,
  getters,
  modules: {
    auth,
    dashboard,
    profile,
    contact,
    robocall,
    sms,
    voiceRecording,
    wakeupCall,
    report,
    shared,
  },
});
