<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Wakeup Call" class="md-size-70">
				<md-table v-model="tableData">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.id }}</md-table-cell>
						<md-table-cell md-label="Created Date ">{{
							item.created_at
						}}</md-table-cell>
						<md-table-cell md-label="Account Code ">{{
							item.account_code
						}}</md-table-cell>
						<md-table-cell md-label="Start Date">{{
							item.scheduled_date
						}}</md-table-cell>
						<md-table-cell md-label="End Date">{{
							item.scheduled_date
						}}</md-table-cell>
						<md-table-cell md-label="Time">{{
							item.scheduled_time
						}}</md-table-cell>
						<md-table-cell md-label="Days To Calls">{{
							item.target
						}}</md-table-cell>
						<md-table-cell md-label="No. Of Calls Per Day">{{
							item.recording
						}}</md-table-cell>
						<md-table-cell md-label="Calls Intervals">{{
							item.recording
						}}</md-table-cell>
						<md-table-cell md-label="Actions">
							<md-button class="md-just-icon md-danger"
								><i class="fa fa-play"></i
							></md-button>
							<md-button class="md-just-icon md-success"
								><md-icon>edit</md-icon></md-button
							>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>

			<div class="md-layout-item md-medium-size-100 md-size-30">
				<form action="" method="post">
					<md-card>
						<md-card-header class="md-card-header-icon md-card-header-green">
							<h4 class="title">New Wakeup Call</h4>
							<p class="text-danger">
								SERVER TIME: Thursday 18th of August 2022 11:32:08 AM
							</p>
						</md-card-header>
						<md-card-content>
							<hr />
							<div class="md-layout">
								<div class="md-layout-item md-size-50">
									<md-field>
										<label>Start Day</label>
										<md-select name="year" id="year">
											<md-option value="fight-club" selected
												>--select day--</md-option
											>
											<md-option
												value="fight-club"
												v-for="range in range(1, 31)"
												:key="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-50">
									<md-field>
										<label> Start Month</label>
										<md-select name="month" id="month">
											<md-option value="" selected>--select month--</md-option>
											<md-option
												value="fight-club"
												v-for="range in range(1, 12)"
												:key="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-50">
									<md-field>
										<label>End Day</label>
										<md-select name="day" id="day">
											<md-option value="" selected>--select day--</md-option>
											<md-option
												value="fight-club"
												v-for="range in range(1, 31)"
												:key="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-50">
									<md-field>
										<label>End Month</label>
										<md-select name="day" id="day">
											<md-option value="" selected>--select day--</md-option>
											<md-option
												value="fight-club"
												v-for="range in range(1, 31)"
												:key="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
							</div>
							<div class="md-layout">
								<div class="md-layout-item md-size-33">
									<md-field>
										<label>Hour</label>
										<md-select name="year" id="year">
											<md-option value="fight-club" selected
												>--select hour--</md-option
											>
											<md-option
												value="fight-club"
												v-for="range in range(1, 12)"
												:key="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-33">
									<md-field>
										<label> Minute</label>
										<md-select name="minute" id="minute">
											<md-option value="" selected>--select minute--</md-option>
											<md-option
												value="fight-club"
												v-for="range in range(0, 59)"
												:key="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-33">
									<md-field>
										<label>GMT</label>
										<md-select name="gmt" id="gmt">
											<md-option value="" selected>--select gmt--</md-option>
											<md-option>AM</md-option>
											<md-option>PM</md-option>
										</md-select>
									</md-field>
								</div>
							</div>
							<div class="md-layout-item">
								<md-field>
									<label for="movies">Days to call</label>
									<md-select name="movies" id="movies" multiple>
										<md-option value="fight-club">Everyday</md-option>
										<md-option value="godfather">Monday</md-option>
										<md-option value="godfather-ii">Tuesday</md-option>
										<md-option value="godfather-iii">Wednesday</md-option>
										<md-option value="godfellas">Thursday</md-option>
										<md-option value="pulp-fiction">Friday</md-option>
										<md-option value="scarface">Saturday</md-option>
										<md-option value="scarface">Sunday</md-option>
									</md-select>
								</md-field>
							</div>
							<div class="md-layout">
								<div class="md-layout-item md-size-33">
									<md-field>
										<label> Daily Calls</label>
										<md-select name="year" id="year">
											<md-option value="fight-club" selected
												>--calls per day--</md-option
											>
											<md-option
												value="fight-club"
												v-for="range in range(1, 12)"
												:key="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-33">
									<md-field>
										<label> Intervals</label>
										<md-select name="minute" id="minute">
											<md-option value="" selected>--select minute--</md-option>
											<md-option
												value="fight-club"
												v-for="range in range(0, 12)"
												:key="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
								<div class="md-layout-item md-size-33">
									<md-field>
										<label> Intervals</label>
										<md-select name="minute" id="minute">
											<md-option value="" selected>--select minute--</md-option>
											<md-option
												value="fight-club"
												v-for="range in range(0, 59)"
												:key="range"
											>
												{{ range }}
											</md-option>
										</md-select>
									</md-field>
								</div>
							</div>
							<div class="md-layout-item">
								<md-field>
									<label for="movie">Contact Group</label>
									<md-select name="movie" id="movie" multiple>
										<md-option value="fight-club" selected
											>--choose list--</md-option
										>
										<md-option value="godfather">Godfather</md-option>
										<md-option value="godfather-ii">Godfather II</md-option>
										<md-option value="godfather-iii">Godfather III</md-option>
										<md-option value="godfellas">Godfellas</md-option>
										<md-option value="pulp-fiction">Pulp Fiction</md-option>
										<md-option value="scarface">Scarface</md-option>
									</md-select>
								</md-field>
							</div>
							<div class="md-layout-item">
								<!-- <md-radio v-model="radio1" :value="true">Radio is on</md-radio> -->
								<md-radio>Send Wakeup Now</md-radio>
								<md-radio>Send Wakeup With Time</md-radio>
							</div>
						</md-card-content>

						<md-card-actions md-alignment="right">
							<md-button class="md-success">Send</md-button>
						</md-card-actions>
					</md-card>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";

export default {
	components: {
		Table,
	},
	data() {
		return {
			tableData: [
				{
					id: 1,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "BAC",
				},
				{
					id: 2,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "AVS",
				},
				{
					id: 3,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "ABE",
				},
				{
					id: 4,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "ABC",
				},
			],
		};
	},

	methods: {
		range(min, max) {
			return [...Array(max - min + 1).keys()].map((i) => i + min);
		},
	},
};
</script>
