import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Auth/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Pages
import Login from "@/pages/Auth/Login.vue";
import Lock from "@/pages/Auth/Lock.vue";

// Contacts
import ContactLayout from "@/pages/Contacts/ContactLayout.vue";
import Contacts from "@/pages/Contacts/Contacts.vue";
import ContactsAdd from "@/pages/Contacts/ContactsAdd.vue";
import ContactList from "@/pages/Contacts/ContactList.vue";
import ContactListArchives from "@/pages/Contacts/ContactListArchives.vue";
import ContactGroup from "@/pages/Contacts/ContactGroup.vue";
import ContactGroupArchive from "@/pages/Contacts/ContactGroupArchive.vue";
import ContactsExtended from "@/pages/Contacts/ContactsExtended.vue";

// Robocalls
import RobocallLayout from "@/pages/Robocalls/RobocallLayout.vue";
import ScheduledRoboCalls from "@/pages/Robocalls/ScheduledRoboCalls.vue";
import ArchivedRoboCalls from "@/pages/Robocalls/ArchivedRoboCalls.vue";

// Wakeup Calls
import WakeupCallLayout from "@/pages/WakeupCalls/WakeupCallLayout.vue";
import ScheduledWakeupCall from "@/pages/WakeupCalls/ScheduledWakeupCall.vue";
import ArchivedWakeupCall from "@/pages/WakeupCalls/ArchivedWakeupCall.vue";

// SMS Text
import SMSTextLayout from "@/pages/SMSText/SMSTextLayout.vue";
import SMSTextSchedule from "@/pages/SMSText/SMSTextSchedule.vue";
import SMSTextArchived from "@/pages/SMSText/SMSTextArchived.vue";

// Recordings
import RecordingsLayout from "@/pages/VoiceRecording/RecordingsLayout.vue";
import RecordingsList from "@/pages/VoiceRecording/RecordingsList.vue";
import RecordingsArchive from "@/pages/VoiceRecording/RecordingsArchive.vue";

// Reports
import ReportsLayout from "@/pages/Reports/ReportsLayout.vue";
import CallsReport from "@/pages/Reports/CallsReport.vue";
import SMSReport from "@/pages/Reports/SMSReport.vue";
import PaymentsReport from "@/pages/Reports/PaymentsReport.vue";

// User
import UserLayout from "@/pages/UserProfile/UserLayout.vue";
import UserProfile from "@/pages/UserProfile/UserProfile.vue";

let authPages = {
  path: "auth",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

let contacts = {
  path: "contacts",
  component: ContactLayout,
  name: "LayoutContacts",
  redirect: "/contacts",
  children: [
      {
        path: "/",
        name: "Contacts",
        component: Contacts,
      },
      {
        path: "new",
        name: "New Contacts",
        component: ContactsAdd,
      },
      {
        path: "contactsext",
        name: "Contacts Extended",
        component: ContactsExtended,
      },
      {
        path: "list",
        name: "Contact List",
        component: ContactList,
      },
      {
        path: "list/archived",
        name: "Archived Contact",
        component: ContactListArchives,
      },
      {
        path: "groups",
        name: "Contact Groups",
        component: ContactGroup,
      },
      {
        path: "groups/archives",
        name: "Contact Archived Groups",
        component: ContactGroupArchive,
      },
      
  ],
};

let robocalls = {
  path: "robocalls",
  component: RobocallLayout,
  name: "Robocall Layout",
  redirect: "/robocalls",
  children: [
      {
        path: "/",
        name: "Schedule Robocall List",
        component: ScheduledRoboCalls,
      },
      {
        path: "archive",
        name: "Archived Robocall List",
        component: ArchivedRoboCalls,
      },
  ],
};

let users = {
  path: "profile",
  component: UserLayout,
  name: "User Layout",
  redirect: "/profile",
  children: [
      {
        path: "",
        name: "Profile",
        component: UserProfile,
      },
      // {
      //   path: "archive",
      //   name: "Archived Robocall List",
      //   component: ArchivedRoboCalls,
      // },
  ],
};

let wakeupCalls = {
  path: "wakeupcalls",
  component: WakeupCallLayout,
  name: "Wakeup Call Layout",
  redirect: "/wakeupCalls",
  children: [
      {
        path: "/",
        name: "Schedule Wakeup Call List",
        component: ScheduledWakeupCall,
      },
      {
        path: "archive",
        name: "Archived WakeupCall",
        component: ArchivedWakeupCall,
      },
  ],
};

let smsText = {
  path: "smsText",
  component: SMSTextLayout,
  name: "SMS Text Layout",
  redirect: "/smsText",
  children: [
      {
        path: "/",
        name: "Schedule SMS Text",
        component: SMSTextSchedule,
      },
      {
        path: "archive",
        name: "Archived SMS Text",
        component: SMSTextArchived,
      },
  ],
};

let recordings = {
  path: "recordings",
  component: RecordingsLayout,
  name: "Recordings Layout",
  redirect: "/recordings",
  children: [
      {
        path: "/",
        name: "Voice Recordings",
        component: RecordingsList,
      },
      {
        path: "archive",
        name: "archived Recordings",
        component: RecordingsArchive,
      },
  ],
};

let reports = {
  path: "reports",
  component: ReportsLayout,
  name: "Reports Layout",
  redirect: "/calls",
  children: [
      {
        path: "/",
        name: "Calls Reports",
        component: CallsReport,
      },
      {
        path: "sms",
        name: "SMS Reports",
        component: SMSReport,
      },
      {
        path: "payments",
        name: "Payments Reports",
        component: PaymentsReport,
      },
  ],
};
 
export const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
  },
  authPages,
  {
    path: "/",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },

      contacts,
      robocalls,
      wakeupCalls,
      smsText,
      recordings,
      reports,
      users

    ],
  },
];

// export default routes;
