import Request from '@/Request'


export function updateMyProfile(payload) {
  return Request({
    url: '/updateprofile',
    method: 'get',
    params:payload
  })
}


export function getMyProfile(payload) {
  return Request({
    url: '/getprofilerecords',
    method: 'get',
    params:payload
  })
}


export function updateMyPassword(payload) {
  return Request({
    url: '/changepassword',
    method: 'get',
    params:payload
  })
}