<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Archived Wakeup Call List">
				<md-table v-model="tableData">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.id }}</md-table-cell>
						<md-table-cell md-label="Created Date ">{{
							item.created_at
						}}</md-table-cell>
						<md-table-cell md-label="Account Code ">{{
							item.account_code
						}}</md-table-cell>
						<md-table-cell md-label="Start Date">{{
							item.scheduled_date
						}}</md-table-cell>
						<md-table-cell md-label="End Date">{{
							item.scheduled_date
						}}</md-table-cell>
						<md-table-cell md-label="Time">{{
							item.scheduled_time
						}}</md-table-cell>
						<md-table-cell md-label="Days To Calls">{{
							item.target
						}}</md-table-cell>
						<md-table-cell md-label="No. Of Calls Per Day">{{
							item.recording
						}}</md-table-cell>
						<md-table-cell md-label="Calls Intervals">{{
							item.recording
						}}</md-table-cell>
						<md-table-cell md-label="Target Groups">Group one</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";

export default {
	components: {
		Table,
	},
	data() {
		return {
			tableData: [
				{
					id: 1,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "BAC",
				},
				{
					id: 2,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "AVS",
				},
				{
					id: 3,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "ABE",
				},
				{
					id: 4,
					created_at: "10/09/2022",
					scheduled_date: "10/09/2022",
					scheduled_time: "10:30AM",
					target: "First contact group",
					recording: "Welcome.mp4",
					account_code: "ABC",
				},
			],
		};
	},
};
</script>
