import Request from "@/Request";

export function getContacts(payload) {
  return Request({
    url: "/getlistrecords",
    method: "get",
    params: payload,
  });
}

export function addContactsToList(payload) {
  return Request({
    url: "/addarecordtolist",
    method: "get",
    params: payload,
  });
}

export function editContactInList(payload) {
  return Request({
    url: "/editrecordinlist",
    method: "get",
    params: payload,
  });
}

export function deleteContactFromList(payload) {
  return Request({
    url: "/deleterecordsfromlist",
    method: "get",
    params: payload,
  });
}

export function uploadContactsToList(payload) {
  return Request({
    url: "/importlist",
    method: "post",
    data: payload,
  });
}

/**
 * Get Contact List Starts Here
 */
export function getContactListWithCount(payload) {
  return Request({
    url: "/getlistnamescount",
    method: "get",
    params: payload,
  });
}

export function getContactList(payload) {
  return Request({
    url: "/getlistnames",
    method: "get",
    params: payload,
  });
}

export function createContactList(payload) {
  return Request({
    url: "/createlist",
    method: "get",
    params: payload,
  });
}

export function archiveContactList(payload) {
  return Request({
    url: "/archivelist",
    method: "get",
    params: payload,
  });
}

export function deleteContactList(payload) {
  return Request({
    url: "/deletelist",
    method: "get",
    params: payload,
  });
}

export function activateContactList(payload) {
  return Request({
    url: "/activatelist",
    method: "get",
    params: payload,
  });
}

export function getArchivedContactList(payload) {
  return Request({
    url: "/getarchivedlistnames",
    method: "get",
    params: payload,
  });
}

/**
 * Contact Groups Start Here
 */

export function getContactGroups(payload) {
  return Request({
    url: "/getlistgroupnamescount",
    method: "get",
    params: payload,
  });
}

export function createContactGroup(payload) {
  return Request({
    url: "/createlistgroup",
    method: "get",
    params: payload,
  });
}

export function addListToContactGroup(payload) {
  return Request({
    url: "/addlisttogroup",
    method: "get",
    params: payload,
  });
}

export function archiveContactGroup(payload) {
  return Request({
    url: "/archivelistgroup",
    method: "get",
    params: payload,
  });
}

export function getArchivedContactGroup(payload) {
  return Request({
    url: "/getarchivedlistgroup",
    method: "get",
    params: payload,
  });
}
