import Request from '@/Request'


export function addNewRobocall(payload) {
  return Request({
    url: '/newrobocall',
    method: 'get',
    params: payload
  })
}

export function ListRoboCalls(payload) {
  return Request({
    url: '/scheduledrobocalls',
    method: 'get',
    params: payload
  })
}

export function ListArchivedRoboCalls(payload) {
  return Request({
    url: '/archivedrobocalls',
    method: 'get',
    params: payload
  })
}