import { addNewRobocall, ListRoboCalls, ListArchivedRoboCalls } from '@/api/robocall'
import store from '@/store';


export const newRobocall = ({ commit }, payload) => {
    
  return new Promise((resolve, reject) => {
    addNewRobocall(payload).then((response) => {
        // console.log(response);
        resolve(response);
    }).catch((error) => {
    }) 
  })
}

export const listRobocalls = ({ commit }, payload) => {
    
  return new Promise((resolve, reject) => {
    ListRoboCalls(payload).then((response) => {
        if (response && response.records && response.records.length) {
            store.commit("robocall/listRobocalls", response.records);
        }
    }).catch((error) => {
    }) 
  })
}

export const listArchivedRoboCalls = ({ commit }, payload) => {
    
  return new Promise((resolve, reject) => {
    ListArchivedRoboCalls(payload).then((response) => {
        if (response && response.records && response.records.length) {
            store.commit("robocall/listArchivedRobocalls", response.records);
        }
    }).catch((_error) => {
    }) 
  })
}