import { uploadVoiceFiles, listVoiceFiles } from '@/api/voiceRecording'
import store from '@/store';


export const uploadVoiceFile = ({ commit }, payload) => {
    
  return new Promise((resolve, reject) => {
    uploadVoiceFiles(payload).then((response) => {
        if (response.statuscode == '200') {
            listFiles(payload);
        }
        
        resolve(response);
    }).catch((error) => {
    }) 
  })
}


export const listFiles = ({ commit }, payload) => {
    
  return new Promise((resolve, reject) => {
    listVoiceFiles(payload).then((response) => {
        if (response && response.description === 'success') {
            store.commit("voiceRecording/voiceFiles", response.recordings)
        }
        resolve(response)
    }).catch((error) => {
    }) 
  })
}