<template>
	<div v-if="success" class="alert alert-success">
		<button
			@click="hideAlert()"
			type="button"
			aria-hidden="false"
			class="close"
		>
			×</button
		><span><b> Success - </b> {{ success }} </span>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			success: "shared/getSuccessResponse",
		}),
	},
	methods: {
		hideAlert() {
			this.$store.commit("shared/setSuccessResponse", null);
		},
	},
};
</script>
