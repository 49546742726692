import store from "@/store";

export const beforeEach = (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.requiresAuth);
  const currentUser = store.state.auth.currentUser;

  if (requireAuth && !currentUser) {
    // next({ name: 'users.login', query: { redirect: to.fullPath } })
    next("/login");
  } else if (to.path === "/login" && currentUser) {
    next({ path: "/dashboard" });
  } else {
    next();
  }
};
