<template>
	<div class="content">
		<div class="md-layout">
			<Table title="Voice Recording Archive List">
				<md-table v-model="tableData">
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="#">{{ item.id }}</md-table-cell>
						<md-table-cell md-label="Created Date ">{{
							item.created_at
						}}</md-table-cell>
						<md-table-cell md-label="File Name ">{{
							item.file_name
						}}</md-table-cell>
						<md-table-cell md-label="Actions">
							<md-button class="md-just-icon md-danger"
								><i class="fa fa-play"></i
							></md-button>
							<md-button class="md-just-icon md-success"
								><md-icon>edit</md-icon></md-button
							>
						</md-table-cell>
					</md-table-row>
				</md-table>
			</Table>
		</div>
	</div>
</template>

<script>
import Table from "@/components/Tables/Default.vue";

export default {
	components: {
		Table,
	},
	data() {
		return {
			tableData: [
				{
					id: 1,
					created_at: "10/09/2022",
					file_name: "Welcome.mp4",
				},
				{
					id: 2,
					created_at: "10/09/2022",
					file_name: "Welcome.mp4",
				},
				{
					id: 3,
					created_at: "10/09/2022",
					file_name: "Welcome.mp4",
				},
				{
					id: 4,
					created_at: "10/09/2022",
					file_name: "Welcome.mp4",
				},
			],
		};
	},
};
</script>
